<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div>
		<BTabs v-model="focusedTab" class="mb-4 tabs">
			<BTab
				v-for="(tab, index) in tabs"
				:key="index"
				class="tabContent"
				:title="tab"
				:data-test-automation-id="getAutomationId('tab-select')"
				:data-tracking-id="getAutomationId('tab-select')"
				@click="onClick(index)"
			>
				<template #title>
					<div v-if="index === tabWithIcon && showIcon">
						<slot name="title"> </slot>
						<FontAwesomeIcon
							class="ml-1 tab-icon"
							:icon="icon"
							:size="iconSize"
							aria-hidden="true"
						/>
					</div>
				</template>
				<slot :name="index"></slot>
			</BTab>
		</BTabs>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders tabs for components to be displayed in.
//
//*************************************
//              HOW TO USE
//*************************************
// This component accepts a array of strings, one for each tab needed. The String will be used as the name of the tab.
// It also uses named slots, but since some tab names might have spaces these name slots are using the index as the name.
// When calling a named slot from a parent component you must use:
// <template v-slot:0>With your content inserted here. </template>

import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import { BTabs, BTab } from 'bootstrap-vue';
// @vue/component
@Component({
	name: 'BaseTabs',
	components: {
		BTabs,
		BTab
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		// This is the list of tabs that will be displayed.
		tabs: {
			type: Array,
			default: () => ['']
		},
		/**
		 * This prop is used for test automation Id's
		 */
		automationId: {
			type: String,
			required: true
		},
		/**
		 * This prop specify the tab index that have an icon near tab name
		 */
		tabWithIcon: {
			type: Number,
			default: null
		},
		/**
		 * This prop is flag to specify when show the icon
		 */
		showIcon: {
			type: Boolean,
			default: false
		},
		/**
		 * These props describe the icon
		 */
		icon: {
			type: Array,
			default: null
		},
		iconSize: {
			type: String,
			default: null
		},
		iconPosition: {
			type: String,
			default: 'right'
		},
		tabIndex: {
			type: Number,
			default: 0
		}
	}
})
export default class BaseTabs extends Vue {
	focusedTab = null;

	created() {
		this.focusedTab = this.tabIndex;
	}
	/**
	 * Handle on click event.
	 */
	onClick(index) {
		this.$emit('click', index);
	}
}
</script>

<style lang="scss" scoped>
.tabs ::v-deep .nav-link {
	font-family: $josefin-font-family;
	color: $input-border-focus-blue;
	border-radius: 10px 10px 0px 0px;
	background-color: white;
	font-weight: $font-regular;
	font-size: 1em;
	margin-left: 10px;
	border: 1px solid $light-grey;
	transition: background-color 0.2s;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}
.tabs ::v-deep .nav-link:active {
	font-family: $josefin-font-family;
	color: $black-text;
	border-radius: 10px 10px 0px 0px;
	font-weight: $font-regular;
	font-size: 1em;
	margin-left: 10px;
}
.tabs ::v-deep .nav-link:hover:not(.active) {
	background-color: $dark-blue;
	color: $white;
}
.tabs ::v-deep .nav-tabs {
	border: none;
}
.tabContent {
	background-color: white;
	border: 1px solid $light-grey;
	border-radius: 10px 10px 10px 10px;
}
.tabs ::v-deep a[aria-selected='true'] {
	border-bottom: 1px solid white;
	color: black;
}
.tabs ::v-deep a[role='tab'] {
	@media (min-width: 355px) and (max-width: 480px) {
		max-width: 150px;
		height: 65px;
	}
	@media (max-width: 355px) {
		max-width: 125px;
		height: 80px;
	}
}
</style>
